import React from 'react'

// material-ui
import { Box, useMediaQuery, Chip, Grid } from '@mui/material'
import useMenu from 'hooks/useMenu'

// project import
import Profile from './Profile'
import Breadcrumbs from 'components/@extended/Breadcrumbs'
import useAuth from 'hooks/useAuth'
import PortfolioSelect from './PortfolioSelect'
import LinksStatus from 'components/common/LinksStatus'

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const { menu: navigation } = useMenu()

  const { userTenant, user } = useAuth()

  return (
    <Grid container justifyContent={'space-between'} alignItems={'center'}>
      <Grid item>
        {!matchesXs && (
          <Grid item>
            <Box sx={{ width: '100%', ml: 1 }}>
              <Breadcrumbs navigation={navigation} card={false} divider={false} />
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid item>
        {userTenant && (
          <Grid container alignItems={'center'} columnSpacing={2}>
            <Grid item>
              <LinksStatus />
            </Grid>
            <Grid item>
              <PortfolioSelect />
            </Grid>

            <Grid item>
              <Chip
                label={userTenant.toUpperCase()}
                size="small"
                // variant="outlined"
                sx={{
                  ...{
                    backgroundColor: '#323437',
                    fontSize: '12px',
                    py: 1.5,
                    px: 0.5,
                    borderRadius: '4px',
                  },
                  ...(user?.preferences?.color && {
                    borderColor: user?.preferences?.color,
                    color: user?.preferences?.color,
                  }),
                }}
              />
            </Grid>

            {!matchesXs && (
              <Grid item>
                <Profile />
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default HeaderContent
