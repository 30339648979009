import { pmsAccountTypes, providers } from 'merklebase-utils/commons'
import { camelCaseToWords } from 'utils/formatter'

const hasUnmappedSubAccounts = (account, portfoliosCountByAccountId, subAccountsByAccountId) => {
  return (
    subAccountsByAccountId[account.id]?.some(
      (subAccount) =>
        portfoliosCountByAccountId[subAccount.id] === 0 ||
        hasUnmappedSubAccounts(subAccount, portfoliosCountByAccountId, subAccountsByAccountId)
    ) ?? false
  )
}

export const mapAccounts = ({
  account,
  allAccounts,
  linksById,
  portfoliosById,
  portfoliosCountByAccountId,
  subAccountsByAccountId,
}) => {
  return {
    id: account.id,
    name: account.name,
    accountTypeId: account.account_type_id,
    accountType: camelCaseToWords(pmsAccountTypes[account.account_type_id]?.name ?? ''),
    provider: {
      id: account.provider_id,
      ticker: providers[account.provider_id]?.ticker,
    },
    number: account.nisAccountInPortfolioumber,
    link: linksById[account.link_id],
    parentAccount: allAccounts.find((a) => a.id === account.account_parent_id),
    subAccounts: allAccounts.filter((a) => a.account_parent_id === account.id),
    portfolio: portfoliosById[account.portfolio_id],
    unmapped: portfoliosCountByAccountId ? portfoliosCountByAccountId[account.id] === 0 : undefined,
    hasUnmappedSubAccounts:
      subAccountsByAccountId && portfoliosCountByAccountId
        ? hasUnmappedSubAccounts(account, portfoliosCountByAccountId, subAccountsByAccountId)
        : undefined,
  }
}
