import { gridClasses } from '@mui/x-data-grid-premium'

// ==============================|| OVERRIDES - DATAGRID ||============================== //
export default function DataGrid(theme) {
  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderWidth: 0,
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
          [`& .${gridClasses.row}.even`]: {
            backgroundColor: theme.palette.background.default,
            '&:hover': {
              backgroundColor: theme.palette.grey[300],
              cursor: 'pointer',
            },
          },
          [`& .${gridClasses.row}.odd`]: {
            backgroundColor: theme.palette.background.paper,
            '&:hover': {
              backgroundColor: theme.palette.grey[300],
              cursor: 'pointer',
            },
          },
          [`& .${gridClasses.row}.footer-row`]: {
            backgroundColor: theme.palette.background.default,
          },
          [`& .${gridClasses.row}.footer-row .${gridClasses.cell}`]: {
            backgroundColor: theme.palette.background.active,
            borderWidth: 0,
          },
          [`& .${gridClasses.columnHeaders}`]: {
            color: theme.palette.text.secondary,
            borderColor: theme.palette.secondary.main,
            backgroundColor: theme.palette.background.default,
            fontSize: '0.75rem',
          },
          [`& .${gridClasses.pinnedColumnHeaders}`]: {
            color: theme.palette.text.secondary,
            borderColor: theme.palette.secondary.main,
            backgroundColor: theme.palette.background.default,
            backgroundImage: 'none',
          },
          [`& .${gridClasses.cell}`]: {
            borderWidth: 0,
          },
          [`& .${gridClasses.cell}:focus`]: {
            outline: 'none',
          },
          [`& .${gridClasses.panelContent}`]: {
            backgroundColor: theme.palette.background.default,
          },
          [`& .${gridClasses.panelWrapper}`]: {
            backgroundColor: theme.palette.background.default,
          },
          [`& .${gridClasses.aggregationColumnHeaderLabel}`]: {
            display: 'none',
          },
          [`& .${gridClasses.footerCell}`]: {
            color: theme.palette.text.primary,
          },
        },
        panel: {
          [`& .${gridClasses.panelWrapper}`]: {
            backgroundColor: theme.palette.background.default,
            borderRadius: 4,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.palette.grey[300],
          },
          [`& .${gridClasses.filterForm}`]: {
            width: 500,
          },
          [`& .${gridClasses.filterFormValueInput}`]: {
            width: 'fit-content',
          },
        },
      },
    },
  }
}
