import dayjs from 'dayjs'

export const numberToUSD = ({
  value,
  plus = false,
  round = false,
  decimal = null,
  currency = true,
}) => {
  // Try parsing the input as a number
  let number = parseFloat(value)

  // Check if the parsed number is NaN
  if (isNaN(number)) {
    return 'Invalid number'
  }

  // Apply rounding if requested
  if (round) {
    number = Math.round(number)
  }

  // Format the number to USD with two decimal places and commas
  const formattedNumber = number.toLocaleString('en-US', {
    ...(currency && {
      style: 'currency',
      currency: 'USD',
    }),
    minimumFractionDigits: 0,
    maximumFractionDigits:
      decimal !== null ? decimal : Math.abs(number) < 1 ? 8 : Math.abs(number) < 5 ? 2 : 0,
  })

  // Add '+' or '-' sign if requested
  if (plus) {
    const sign = Math.sign(number) === -1 ? '-' : '+'
    return `${sign}${formattedNumber}`
  }

  return formattedNumber
}

// Format date to ** min ago, ** hours ago, ** days ago
export const dateFormater = (miliDate) => {
  if (!miliDate) {
    return ''
  }

  const date = new Date(miliDate)
  const now = new Date()
  const diff = (now - date) / 1000
  const days = Math.floor(diff / 86400)
  const hours = Math.floor(diff / 3600)
  const mins = Math.floor(diff / 60)
  const secs = Math.floor(diff)
  if (secs < 60) {
    return `${secs} seconds ago`
  } else if (mins < 60) {
    return `${mins} minutes ago`
  } else if (hours < 24) {
    return `${hours} hours ago`
  } else if (days < 7) {
    return `${days} days ago`
  } else if (days < 30) {
    return `${Math.floor(days / 7)} weeks ago`
  } else if (days < 365) {
    return `${Math.floor(days / 30)} months ago`
  } else {
    return `${Math.floor(days / 365)} years ago`
  }
}

export const formatCoinAmount = (params) => {
  const { value = '', decimal = 6 } = params || {}

  return value && !isNaN(value)
    ? Number(value)
        .toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: decimal,
        })
        .replace('$', '')
    : value
}

export const getShortAddress = (address) => {
  return address.length > 12 ? `${address.slice(0, 6)}...${address.slice(-4)}` : address
}

export const capitalize = (string) => {
  if (!string) return ''

  return string
    .split(/([ -]|(?=[A-Z]))/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('')
}

export const camelCaseToWords = (string) => {
  if (!string) return ''
  return string
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase())
    .trim()
}

export const worldsToCamelCase = (string) => {
  return string
    .replace(/\s(.)/g, function ($1) {
      return $1.toUpperCase()
    })
    .replace(/\s/g, '')
}

export const createDateAndTime = (date) =>
  `${new Date(date).toLocaleDateString()} ${new Date(date).toLocaleTimeString()}`

export const formatInstrumentDate = (date) => dayjs(date).format('DDMMMYY').toString().toUpperCase()

export const formatInstrumentName = ({ date, currency, type, price }) => {
  const dateFormatted = formatInstrumentDate(date)

  return `${currency}-${dateFormatted}-${price}-${type === 'put' ? 'P' : 'C'}`
}

export const formatUSDColumn = ({ value }) => {
  if (!value) return value

  if (Math.abs(value) > 1) {
    return numberToUSD({ value: value, decimal: 2 })
  }
  return numberToUSD({ value: value, decimal: 6 })
}

export const numberToHour = (value) => {
  const period = value < 12 ? 'AM' : 'PM'
  const displayHour = value % 12 === 0 ? 12 : value % 12
  return `${displayHour}:00 ${period}`
}
