// ==============================|| OVERRIDES - AUTOCOMPLETE ||============================== //

export default function Autocomplete() {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            padding: '0px 0px',
          },
          '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
            padding: '7px 10px 7px 10px',
          },
        },
        popupIndicator: {
          width: 'auto',
          height: 'auto',
        },
        clearIndicator: {
          width: 'auto',
          height: 'auto',
        },
      },
    },
  }
}
