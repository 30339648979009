import useAuth from 'hooks/useAuth'
import { useMemo, useCallback } from 'react'

// project import
import {
  useAddAccountsToPortfolioMutation,
  useCreatePortfolioMutation,
  useEditPortfolioMutation,
  useGetPortfoliosQuery,
  useRemoveAccountsFromPortfolioMutation,
  useRemovePortfolioMutation,
  useUpdateUserPortfoliosMutation,
  useAddPortfolioToPortfolioMutation,
  useRemovePortfolioFromPortfolioMutation,
  useGetHistoricalAUMQuery,
  useRemoveHistoricalAUMMutation,
  useUpdateHistoricalAUMMutation,
  useReplaceAccountsInPortfolioMutation,
  useReplacePortfoliosInPortfolioMutation,
} from 'store/api/merklebaseAPI'
import { mapPortfolios } from 'utils/mappers/portfolios'

// ----------------------------------------------------------------------

export default function usePortfolios() {
  const { user: { id: userId, portfoliosIds: selectedPortfoliosIds } = {} } = useAuth()

  const {
    data: { portfolios = [] } = {},
    isLoading: isPortfoliosLoading,
    isFetching: isPortfoliosFetching,
    isUninitialized: isPortfoliosUninitialized,
  } = useGetPortfoliosQuery({ ignoreAUM: true })

  const {
    data: { totalAUM = 0 } = {},
    isLoading: isTotalAUMLoading,
    isFetching: isTotalAUMFetching,
    isUninitialized: isTotalAUMUninitialized,
  } = useGetPortfoliosQuery({ ignoreAUM: false })

  const [createPortfolio, isCreatePortfolioLoading] = useCreatePortfolioMutation()

  const [updatePortfolio, isUpdatePortfolioLoading] = useEditPortfolioMutation()

  const [removePortfolioRequest, isRemovePortfolioLoading] = useRemovePortfolioMutation()

  const [updateUserPortfolios, { isLoading: isUpdateUserPortfoliosLoading }] =
    useUpdateUserPortfoliosMutation()

  const [addAccountsToPortfolio, isAddAccountsToPortfolioLoading] =
    useAddAccountsToPortfolioMutation()

  const [replaceAccountsInPortfolio, { isLoading: isReplaceAccountsInPortfolioLoading }] =
    useReplaceAccountsInPortfolioMutation()

  const [removeAccountsFromPortfolio, isRemoveAccountsFromPortfolioLoading] =
    useRemoveAccountsFromPortfolioMutation()

  const [addPortfolioToPortfolio] = useAddPortfolioToPortfolioMutation()

  const [replacePortfoliosInPortfolio, { isLoading: isReplacePortfoliosInPortfolioLoading }] =
    useReplacePortfoliosInPortfolioMutation()

  const [removePortfolioFromPortfolio] = useRemovePortfolioFromPortfolioMutation()

  const [removeHistoricalAUM, isRemoveHistoricalAUMLoading] = useRemoveHistoricalAUMMutation()

  const [updateHistoricalAUM, isUpdateHistoricalAUMLoading] = useUpdateHistoricalAUMMutation()

  const {
    data: historicalAUM = [],
    isLoading: isHistoricalAUMLoading,
    isFetching: isHistoricalAUMFetching,
  } = useGetHistoricalAUMQuery(
    {
      portfoliosIds: selectedPortfoliosIds,
    },
    {
      skip: !selectedPortfoliosIds,
    }
  )

  const isPortfoliosLoadingState = useMemo(
    () =>
      isPortfoliosLoading ||
      isPortfoliosFetching ||
      isPortfoliosUninitialized ||
      !selectedPortfoliosIds,
    [isPortfoliosLoading, isPortfoliosFetching, isPortfoliosUninitialized, selectedPortfoliosIds]
  )

  const isTotalAUMLoadingState = useMemo(
    () => isTotalAUMLoading || isTotalAUMFetching || isTotalAUMUninitialized,
    [isTotalAUMLoading, isTotalAUMFetching, isTotalAUMUninitialized]
  )

  const mappedPortfolios = useMemo(
    () => portfolios?.map((portfolio) => mapPortfolios({ portfolio, selectedPortfoliosIds })),
    [portfolios, selectedPortfoliosIds]
  )

  const selectedPortfolios = useMemo(() => {
    return mappedPortfolios.filter((portfolio) => portfolio.isSelected)
  }, [mappedPortfolios])

  const portfoliosById = useMemo(
    () =>
      mappedPortfolios.reduce((acc, portfolio) => {
        acc[portfolio.id] = portfolio
        return acc
      }, {}),
    [mappedPortfolios]
  )

  const totalMappedPortfoliosAUM = useMemo(() => {
    return mappedPortfolios.reduce((acc, portfolio) => {
      return acc + portfolio.aum || 0
    }, 0)
  }, [mappedPortfolios])

  const updateSelectedPortfolios = useCallback(
    (portfolios) => {
      updateUserPortfolios({ userId, portfolios })
    },
    [updateUserPortfolios, userId]
  )

  return {
    portfolios: {
      data: mappedPortfolios,
      isLoading: isPortfoliosLoadingState,
    },
    selectedPortfolios: {
      data: selectedPortfolios,
      isLoading: isPortfoliosLoadingState,
    },
    portfoliosById: {
      data: portfoliosById,
      isLoading: isPortfoliosLoadingState,
    },
    createPortfolio: {
      mutation: createPortfolio,
      isLoading: isCreatePortfolioLoading,
    },
    updatePortfolio: {
      mutation: updatePortfolio,
      isLoading: isUpdatePortfolioLoading,
    },
    removePortfolio: {
      mutation: removePortfolioRequest,
      isLoading: isRemovePortfolioLoading,
    },
    updateSelectedPortfolios: {
      mutation: updateSelectedPortfolios,
      isLoading: isUpdateUserPortfoliosLoading,
    },
    addAccountsToPortfolio: {
      mutation: addAccountsToPortfolio,
      isLoading: isAddAccountsToPortfolioLoading,
    },
    replaceAccountsInPortfolio: {
      mutation: replaceAccountsInPortfolio,
      isLoading: isReplaceAccountsInPortfolioLoading,
    },
    removeAccountsFromPortfolio: {
      mutation: removeAccountsFromPortfolio,
      isLoading: isRemoveAccountsFromPortfolioLoading,
    },
    addPortfolioToPortfolio: {
      mutation: addPortfolioToPortfolio,
    },
    replacePortfoliosInPortfolio: {
      mutation: replacePortfoliosInPortfolio,
      isLoading: isReplacePortfoliosInPortfolioLoading,
    },
    removePortfolioFromPortfolio: {
      mutation: removePortfolioFromPortfolio,
    },
    totalAUM: {
      data: totalAUM,
      isLoading: isTotalAUMLoadingState,
    },
    totalMappedPortfoliosAUM: {
      data: totalMappedPortfoliosAUM,
      isLoading: isPortfoliosLoadingState,
    },
    historicalAUM: {
      data: historicalAUM,
      isLoading: isHistoricalAUMLoading || isHistoricalAUMFetching,
    },
    removeHistoricalAUM: {
      mutation: removeHistoricalAUM,
      isLoading: isRemoveHistoricalAUMLoading,
    },
    updateHistoricalAUM: {
      mutation: updateHistoricalAUM,
      isLoading: isUpdateHistoricalAUMLoading,
    },
  }
}
