import { useMemo, useRef, useState } from 'react'

import {
  CaretDownOutlined,
  CaretUpOutlined,
  InfoCircleOutlined,
  ExportOutlined,
} from '@ant-design/icons'
import usePortfolios from 'hooks/models/usePortfolios'

// material-ui
import {
  useTheme,
  Popper,
  Paper,
  ClickAwayListener,
  CardContent,
  List,
  Checkbox,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Grid,
  Button,
  Typography,
  Tooltip,
  Divider,
  IconButton,
} from '@mui/material'

// project import
import Transitions from 'components/@extended/Transitions'
import MainCard from 'components/MainCard'
import { useCallback } from 'react'
import { LoadingButton } from '@mui/lab'
import { useNavigate } from 'react-router'

const createStyles = (theme) => ({
  popper: {
    zIndex: 2001,
  },
  paper: {
    boxShadow: theme.customShadows.z1,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.secondary.main,
  },
  cardContent: {
    '&:last-child': {
      p: 0,
    },
    p: 0,
    backgroundColor: theme.palette.grey[200],
  },
  list: {
    overflow: 'auto',
    maxHeight: '400px',
    width: '330px',
    pt: 0,
    mt: 0.25,
  },
  listItemButton: {
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
  },
  buttonEndIcon: {
    width: '10px',
    height: '10px',
    padding: 0,
    margin: 0,
  },
  menuButton: {
    width: '130px',
    height: '36px',
  },
  tooltipExclamation: {
    color: theme.palette.grey['A100'],
  },
  applyButton: {
    margin: '8px 16px 16px 16px',
    '& .MuiLoadingButton-loadingIndicator': {
      color: theme.palette.text.primary,
    },
  },
  portfolioDeepLinkIcon: {
    color: theme.palette.primary.darker,
  },
})

export const PortfolioSelect = () => {
  const theme = useTheme()
  const styles = createStyles(theme)

  const [isPortfoliosMenuOpen, setIsPortfoliosMenuOpen] = useState(false)
  const navigate = useNavigate()

  const anchorRef = useRef(null)

  const {
    selectedPortfolios: {
      data: persistedSelectedPortfolios,
      isLoading: isPersistedSelectedPortfoliosLoading,
    },
    updateSelectedPortfolios: {
      mutation: updatePersistedSelectedPortfolios,
      isLoading: isUpdatePersistedSelectedPortfoliosLoading,
    },
    portfolios: { data: portfolios },
    portfoliosById: { data: portfoliosById },
  } = usePortfolios()
  const [draftSelectedPortfolios, setDraftSelectedPortfolios] = useState(
    persistedSelectedPortfolios
  )

  const allPortfolios = useMemo(
    () => portfolios.filter((portfolio) => portfolio.isCounterparty === false) || [],
    [portfolios]
  )

  const getIntersectedPortfolio = useCallback(
    (id, selectedPortfolios) => {
      const portfolio = portfoliosById[id]
      const portfolioAccountsIds = portfolio?.accountsIds || []

      if (!selectedPortfolios) {
        selectedPortfolios = draftSelectedPortfolios
      }

      const intersectedPortfolio = selectedPortfolios?.find((selectedPortfolio) => {
        if (selectedPortfolio.id === id) return false
        const pAccountsIds =
          allPortfolios.find((p) => p.id === selectedPortfolio.id)?.accountsIds || []
        return pAccountsIds.some((pAccountId) => portfolioAccountsIds.includes(pAccountId))
      })

      return intersectedPortfolio
    },
    [allPortfolios, draftSelectedPortfolios, portfoliosById]
  )

  const onPortfolioSelect = useCallback(
    (id) => {
      if (draftSelectedPortfolios?.some((selectedPortfolio) => selectedPortfolio.id === id)) {
        setDraftSelectedPortfolios(draftSelectedPortfolios.filter((p) => p.id !== id))
      } else {
        setDraftSelectedPortfolios([...draftSelectedPortfolios, portfoliosById[id]])
      }
    },
    [draftSelectedPortfolios, portfoliosById]
  )

  const isSelectAllChecked = useMemo(() => {
    const draftSelectedPortfoliosById = draftSelectedPortfolios.reduce((acc, p) => {
      acc[p.id] = p
      return acc
    }, {})

    // if one of portfolios is not selected and not intersected with selected portfolios
    // it means that not all portfolios are selected
    for (const portfolio of allPortfolios) {
      if (!getIntersectedPortfolio(portfolio.id) && !draftSelectedPortfoliosById[portfolio.id]) {
        return false
      }
    }

    return true
  }, [allPortfolios, draftSelectedPortfolios, getIntersectedPortfolio])

  const onSelectAll = useCallback(() => {
    if (isSelectAllChecked) {
      setDraftSelectedPortfolios([])
      return
    }

    let newSelectedPortfolios = []

    for (const portfolio of allPortfolios) {
      if (!getIntersectedPortfolio(portfolio.id, newSelectedPortfolios)) {
        newSelectedPortfolios.push(portfolio)
      }
    }

    setDraftSelectedPortfolios(newSelectedPortfolios)
  }, [allPortfolios, getIntersectedPortfolio, isSelectAllChecked])

  const isSelectAllIndeterminate = useMemo(() => {
    return (
      isSelectAllChecked &&
      draftSelectedPortfolios.length > 0 &&
      draftSelectedPortfolios.length < allPortfolios.length
    )
  }, [allPortfolios.length, draftSelectedPortfolios.length, isSelectAllChecked])

  const onMenuToggle = useCallback(
    (open) => {
      setIsPortfoliosMenuOpen(open ?? !isPortfoliosMenuOpen)
      setDraftSelectedPortfolios(persistedSelectedPortfolios)
    },
    [isPortfoliosMenuOpen, persistedSelectedPortfolios]
  )

  const onApplyClick = useCallback(() => {
    updatePersistedSelectedPortfolios(draftSelectedPortfolios.map((p) => p.id))
    onMenuToggle(false)
  }, [draftSelectedPortfolios, onMenuToggle, updatePersistedSelectedPortfolios])

  const isApplyButtonDisabled = useMemo(() => {
    if (persistedSelectedPortfolios.length !== draftSelectedPortfolios.length) return false
    return persistedSelectedPortfolios.every(
      (val, index) => val?.id === draftSelectedPortfolios[index]?.id
    )
  }, [draftSelectedPortfolios, persistedSelectedPortfolios])

  return (
    <>
      <Button
        ref={anchorRef}
        onClick={onMenuToggle}
        variant="outlined"
        color="secondary"
        sx={styles.menuButton}
        endIcon={
          isPortfoliosMenuOpen ? (
            <CaretUpOutlined style={styles.buttonEndIcon} />
          ) : (
            <CaretDownOutlined style={styles.buttonEndIcon} />
          )
        }
      >
        <Typography variant="subtitle1">
          Portfolio ({persistedSelectedPortfolios?.length})
        </Typography>
      </Button>
      <Popper
        placement="bottom-end"
        open={isPortfoliosMenuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={styles.popper}
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 0],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            type="grow"
            position="top-right"
            in={isPortfoliosMenuOpen}
            {...TransitionProps}
          >
            <Paper sx={styles.paper}>
              <ClickAwayListener onClickAway={() => onMenuToggle(false)}>
                <MainCard elevation={0} border={false} content={false}>
                  <CardContent sx={styles.cardContent}>
                    <ListItem disablePadding>
                      <ListItemButton onClick={onSelectAll}>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={isSelectAllChecked}
                            indeterminate={isSelectAllIndeterminate}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText>
                          <Grid container alignItems={'center'} columnSpacing={1}>
                            <Grid item>Select All</Grid>
                          </Grid>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                    <List sx={styles.list}>
                      {allPortfolios
                        .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
                        .map(({ id, name }) => (
                          <ListItem key={id} disablePadding>
                            <ListItemButton
                              role={undefined}
                              dense
                              disabled={!!getIntersectedPortfolio(id)}
                              onClick={() => !getIntersectedPortfolio(id) && onPortfolioSelect(id)}
                              sx={styles.listItemButton}
                            >
                              <ListItemIcon>
                                <Checkbox
                                  value={id}
                                  edge="start"
                                  checked={draftSelectedPortfolios.map((p) => p.id)?.includes(id)}
                                  disableRipple
                                />
                              </ListItemIcon>
                              <ListItemText id={`checkbox-list-label-${id}`}>
                                <Grid container alignItems={'center'} columnSpacing={1}>
                                  <Grid item>{name}</Grid>
                                  {!!getIntersectedPortfolio(id) && (
                                    <Tooltip
                                      key={id}
                                      title={`Portfolio selection restricted: Overlapping accounts with ${
                                        getIntersectedPortfolio(id)?.name
                                      }`}
                                      placement={'right'}
                                    >
                                      <Grid item>
                                        <InfoCircleOutlined style={styles.tooltipExclamation} />
                                      </Grid>
                                    </Tooltip>
                                  )}
                                </Grid>
                              </ListItemText>
                            </ListItemButton>
                            <IconButton
                              sx={styles.portfolioDeepLinkIcon}
                              onClick={() => {
                                navigate(`/portfolios/${id}`)
                                onMenuToggle(false)
                              }}
                            >
                              <ExportOutlined />
                            </IconButton>
                          </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <ListItem disablePadding>
                      <LoadingButton
                        sx={styles.applyButton}
                        variant="contained"
                        fullWidth
                        disabled={isApplyButtonDisabled}
                        onClick={onApplyClick}
                        loading={
                          isUpdatePersistedSelectedPortfoliosLoading ||
                          isPersistedSelectedPortfoliosLoading
                        }
                      >
                        Apply
                      </LoadingButton>
                    </ListItem>
                  </CardContent>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  )
}

export default PortfolioSelect
