// third-party
import { FormattedMessage } from 'react-intl'
import { Portfolio } from 'assets/images/icons'

// assets
import {
  BorderOutlined,
  BoxPlotOutlined,
  ChromeOutlined,
  DeploymentUnitOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  SmileOutlined,
  StopOutlined,
  DashboardOutlined,
  SettingOutlined,
  InfoCircleOutlined,
  WalletOutlined,
  SwapOutlined,
  LinkOutlined,
  LineChartOutlined,
  DollarOutlined,
  BookOutlined,
  FileTextOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'
import { useMemo } from 'react'
import useAuth from 'hooks/useAuth'

import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined'

// icons
const icons = {
  ChromeOutlined,
  MenuUnfoldOutlined,
  BoxPlotOutlined,
  StopOutlined,
  BorderOutlined,
  SmileOutlined,
  GatewayOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  DashboardOutlined,
  SettingOutlined,
  InfoCircleOutlined,
  SwapOutlined,
  WalletOutlined,
  LinkOutlined,
  Portfolio,
  DollarOutlined,
  LineChartOutlined,
  BookOutlined,
  RequestPageOutlinedIcon,
  FileTextOutlined,
  CheckCircleOutlined,
}

// ==============================|| MENU ITEMS ||============================== //

const useMenu = () => {
  const { user } = useAuth()
  const featureFlags = useMemo(() => user?.featureFlags, [user])

  const menuItems = useMemo(() => {
    const items = {
      id: 'mainMenu',
      type: 'group',
      children: [
        {
          id: 'dashboard',
          title: <FormattedMessage id="dashboard" />,
          type: 'item',
          url: '/dashboard',
          icon: icons.DashboardOutlined,
        },
        {
          id: 'portfolios',
          title: <FormattedMessage id="portfolios" />,
          type: 'item',
          icon: icons.Portfolio,
          url: '/portfolios',
        },
        {
          id: 'balances',
          title: <FormattedMessage id="balances" />,
          type: 'collapse',
          breadcrumbs: true,
          icon: icons.WalletOutlined,
          children: [
            {
              id: 'balances',
              title: <FormattedMessage id="actual-balances" />,
              type: 'item',
              url: '/balances/balances',
            },
            ...(!featureFlags?.includes('newpms')
              ? [
                  {
                    id: 'computed-balances',
                    title: <FormattedMessage id="computed-balances" />,
                    type: 'item',
                    url: '/balances/computed-balances',
                  },
                ]
              : []),
            {
              id: 'margins',
              title: <FormattedMessage id="margins" />,
              type: 'item',
              url: '/balances/margins',
            },
            {
              id: 'defi',
              title: <FormattedMessage id="defi" />,
              type: 'item',
              url: '/balances/defi',
            },
          ],
        },
        ...(user?.organizationName === 'Annamite' || user?.organizationName?.includes('merklebase')
          ? [
              {
                id: 'reports',
                title: <FormattedMessage id="reports" />,
                type: 'item',
                url: '/reports',
                icon: icons.BookOutlined,
              },
            ]
          : []),
        {
          id: 'risk',
          title: <FormattedMessage id="risk" />,
          type: 'collapse',
          icon: icons.InfoCircleOutlined,
          breadcrumbs: true,
          children: [
            {
              id: 'risk',
              title: <FormattedMessage id="risk" />,
              type: 'item',
              url: '/risk/risk',
            },
            {
              id: 'stress-test',
              title: <FormattedMessage id="stress-test" />,
              type: 'item',
              url: '/risk/stress-test',
            },
          ],
        },
        {
          id: 'performance',
          title: <FormattedMessage id="performance" />,
          type: 'collapse',
          icon: icons.LineChartOutlined,
          breadcrumbs: true,
          children: [
            {
              id: 'pnl-breakdown',
              title: <FormattedMessage id="live-pnl" />,
              type: 'item',
              url: '/performance/pnl-breakdown',
            },
            // {
            //   id: 'performance-analysis',
            //   title: <FormattedMessage id="performance-analysis" />,
            //   type: 'item',
            //   url: '/performance/performance-analysis',
            // },
            {
              id: 'history-pnl',
              title: <FormattedMessage id="history-pnl" />,
              type: 'item',
              url: '/performance/history-pnl',
            },
          ],
        },
        {
          id: 'transactions',
          title: <FormattedMessage id="transactions" />,
          type: 'item',
          breadcrumbs: true,
          icon: icons.SwapOutlined,
          url: '/transactions/transactions',
        },
        ...(featureFlags?.includes('newpms')
          ? [
              {
                id: 'reconciliation',
                title: <FormattedMessage id="Reconciliation" />,
                type: 'item',
                breadcrumbs: true,
                icon: icons.CheckCircleOutlined,
                url: '/reconciliation/reconciliation',
              },
            ]
          : []),
        ...(featureFlags?.includes('loans')
          ? [
              {
                id: 'lms',
                title: <FormattedMessage id="lms" />,
                type: 'collapse',
                breadcrumbs: true,
                icon: icons.RequestPageOutlinedIcon,
                children: [
                  {
                    id: 'credit-agreements',
                    title: <FormattedMessage id="credit-agreements" />,
                    type: 'item',
                    url: '/lms/credit-agreements',
                  },
                  {
                    id: 'tranches',
                    title: <FormattedMessage id="tranches" />,
                    type: 'item',
                    url: '/lms/tranches',
                  },
                ],
              },
            ]
          : []),
        ...(featureFlags?.includes('reports-new')
          ? [
              {
                id: 'reports-new',
                title: <FormattedMessage id="reports-new" />,
                type: 'collapse',
                breadcrumbs: true,
                icon: icons.FileTextOutlined,
                url: '/reports-new/all',
                children: [
                  {
                    id: 'reports-new',
                    title: <FormattedMessage id="all-reports" />,
                    url: '/reports-new/all',
                    type: 'item',
                  },
                ],
              },
            ]
          : []),
        {
          id: 'prices',
          title: <FormattedMessage id="prices" />,
          type: 'collapse',
          breadcrumbs: true,
          icon: icons.DollarOutlined,
          children: [
            {
              id: 'prices',
              title: <FormattedMessage id="currencies" />,
              type: 'item',
              url: '/prices/prices',
            },
            {
              id: 'derivatives-prices',
              title: <FormattedMessage id="derivatives-prices" />,
              type: 'item',
              url: '/prices/derivatives-prices',
            },
            {
              id: 'funding-rates',
              title: <FormattedMessage id="funding-rates" />,
              type: 'item',
              url: '/prices/funding-rates',
            },
          ],
        },
        {
          id: 'settings',
          title: <FormattedMessage id="settings" />,
          type: 'collapse',
          breadcrumbs: true,
          icon: icons.SettingOutlined,
          children: [
            {
              id: 'link-management',
              title: <FormattedMessage id="link-management" />,
              type: 'item',
              url: '/settings/link-management',
            },
            {
              id: 'configurations',
              title: <FormattedMessage id="configurations" />,
              type: 'item',
              url: '/settings/configurations',
            },
            {
              id: 'historicalaum',
              title: <FormattedMessage id="historicalaum" />,
              type: 'item',
              url: '/settings/historicalaum',
            },
            {
              id: 'liquidity-tiers',
              title: <FormattedMessage id="liquidity-tiers" />,
              type: 'item',
              url: '/settings/liquidity-tiers',
            },
          ],
        },
      ],
    }

    return items
  }, [featureFlags, user?.organizationName])

  return { menu: { items: [menuItems] } }
}

export default useMenu
